
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseMessageBox2",
  emits: ['emitConfirm', 'emitCancel'],
  props: {
    messageBoxTitle: String,
    confirmButtonText: String,
    cancelButtonText: String,
    messageBoxType: String,
    displayMessageBox: {
      type: Boolean,
      default: false
    },
  },
});
