type Countries = {
  [index: string]: Country;
};

type Country = {
  flag: string;
  name: string;
};

type Languages = {
  abbr: string;
  order: number;
};

export const countries: Countries = {
  ee: {
    flag: "media/flags/estonia.svg",
    name: "Eesti",
  },
  en: {
    flag: "media/flags/united-states.svg",
    name: "English",
  },
  de: {
    flag: "media/flags/germany.svg",
    name: "Deutsch",
  },
  au: {
    flag: "media/flags/australia.svg",
    name: "Australia",
  },
  lt: {
    flag: "media/flags/lithuania.svg",
    name: "Lietuvių",
  },
  ru: {
    flag: "media/flags/russia.svg",
    name: "Pусский",
  },
  uk: {
    flag: "media/flags/ukraine.svg",
    name: "Yкраїнська",
  },
};

export const languages: Languages[] = [
  {
    abbr: "ee",
    order: 1,
  },
  {
    abbr: "en",
    order: 2,
  },
  {
    abbr: "de",
    order: 3,
  },
  {
    abbr: "au",
    order: 4,
  },
  {
    abbr: "lt",
    order: 5,
  },
  {
    abbr: "ru",
    order: 6,
  },
  {
    abbr: "uk",
    order: 7,
  },
];
