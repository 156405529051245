
import { defineComponent, onMounted, watch, computed } from "vue";
import { useRoute } from "vue-router";
import { translate } from "@/core/helpers/config";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/CleanMainMenuConfig";
import { headerMenuIcons } from "@/core/helpers/config";
import { useStore } from "vuex";

export default defineComponent({
  name: "KTMenu",
  components: {},
  setup() {
    const route = useRoute();
    const store = useStore();

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    const hasTenants = () => {
      return store.getters.availableTenants.length !== 0 && store.getters.currentUser.tenant ? true : false
    }

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      hasActiveChildren,
      hasTenants,
      headerMenuIcons,
      MainMenuConfig,
      translate,
    };
  },
});
