<template>
  <!--begin::Menu-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px"
    data-kt-menu="true"
  >
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px me-5">
          <div
            class="symbol-label fs-2 fw-bold text-inverse-primary"
            style="background-color: rgb(5, 179, 132)"
          >{{ userInitial }}</div>
        </div>
        <!--end::Avatar-->

        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div
            class="fw-bolder d-flex align-items-center fs-5"
          >{{ currentUser.name }} {{ currentUser.surname }}</div>
          <span class="fw-bold text-muted text-hover-primary fs-7">
            {{
              truncate(currentUser.email, 25)
            }}
          </span>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->
    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <router-link :to="`/${currentUser.id}/profile/settings`" class="menu-link px-5">My Profile</router-link>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <!--begin::Change Tenant Menu item-->
    <div
      class="menu-item px-5"
      data-bs-toggle="modal"
      data-bs-target="#kt_modal_view_tenants"
      v-if="hasTenants()"
    >
      <div class="menu-link px-5">
        <span class="menu-title position-relative">
          Change Tenant
          <span
            class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0 text-capitalize"
          >{{ currentUser.tenant }}</span>
        </span>
      </div>
    </div>
    <!--end::Change Tenant Menu Item-->

    <!--begin::Menu item-->
    <div
      class="menu-item px-5 my-1"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="left"
      data-kt-menu-flip="center"
    >
      <div class="menu-link px-5">
        <span class="menu-title position-relative">
          Language
          <span
            class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0"
          >
            {{ currentLanguageLocale.name }}
            <img
              class="w-15px h-15px rounded-1 ms-2"
              :src="currentLanguageLocale.flag"
              alt="metronic"
            />
          </span>
        </span>
      </div>

      <!--begin::Menu sub-->
      <div class="menu-sub menu-sub-dropdown w-175px py-4">
        <!--begin::Menu item-->
        <div
          class="menu-item px-3"
          v-for="language in sortedLanguages"
          :key="`menu-item-${language.abbr}`"
        >
          <a
            @click="setLang(language.abbr)"
            href="#"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage(language.abbr) }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                :src="`${countries[language.abbr].flag}`"
                :alt="countries[language.abbr].name"
              />
            </span>
            {{ countries[language.abbr].name }}
          </a>
        </div>
        <!--end::Menu item-->
      </div>
      <!--end::Menu sub-->
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <!-- <div class="menu-item px-5 my-1">
      <router-link
        :to="`/${currentUser.id}/account/settings`"
        class="menu-link px-5"
      >Account Settings</router-link>
    </div>-->
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-5 my-1">
      <a @click="signOut()" class="menu-link px-5">Sign Out</a>
    </div>
    <!--end::Menu item-->
  </div>
  <!--end::Menu-->
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { Actions as AggridActions } from "@/store/enums/AggridEnums";
import { countries, languages } from './user-menu/language'
import { capitalizeText, truncate, currentLang } from "@/core/helpers/config";

export default defineComponent({
  name: "kt-user-menu",
  props: {
    currentUser: {
      type: Object,
    },
    userInitial: {
      type: String,
    },
  },
  components: {},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }))
        .finally(() => {
          document.title = "Bauwise"
        })
    };

    const hasTenants = () => {
      return store.getters.availableTenants.length !== 0 && store.getters.currentUser.tenant ? true : false
    }

    const setLang = (lang) => {
      store.dispatch(Actions.UPDATE_LANGUAGE, { language: lang })
        .then(() => {
          localStorage.setItem("lang", lang);
          i18n.locale.value = lang;
          store.dispatch(AggridActions.UPDATE_COMPONENTKEY_ACTION);
        })
        .catch(error => {
          console.log(error)
        })
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLanguageLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    const sortedLanguages = computed(() => {
      return languages.sort((a, b) => {
        return a.order - b.order
      })
    })

    onMounted(() => {
      const tempLang = localStorage.getItem("lang")
      if (tempLang !== currentLang.value) {
        setLang(tempLang)
        return;
      }
      i18n.locale.value = currentLang.value
        ? currentLang.value
        : "en";
    })

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLanguageLocale,
      countries,
      sortedLanguages,
      capitalizeText,
      truncate,
      hasTenants
    };
  },
});
</script>
