
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
  },
  setup() {
    const store = useStore();

    const checkUserAuth = computed(() => {
      return (
        Object.keys(store.state.AuthModule.user).length !== 0 &&
        store.getters.isUserAuthenticated
      );
    });

    const currentUser = computed(() => {
      if (!checkUserAuth.value) return;
      return store.getters.currentUser;
    });

    const handleUserInitials = computed(() => {
      return `${currentUser.value.name.charAt(
        0
      )}${currentUser.value.surname.charAt(0)}`;
    });

    return {
      checkUserAuth,
      currentUser,
      handleUserInitials,
    };
  },
});
