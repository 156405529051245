
import { defineComponent, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { Actions as AggridActions } from "@/store/enums/AggridEnums";
import { countries, languages } from './user-menu/language'
import { capitalizeText, truncate, currentLang } from "@/core/helpers/config";

export default defineComponent({
  name: "kt-user-menu",
  props: {
    currentUser: {
      type: Object,
    },
    userInitial: {
      type: String,
    },
  },
  components: {},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    const signOut = () => {
      store
        .dispatch(Actions.LOGOUT)
        .then(() => router.push({ name: "sign-in" }))
        .finally(() => {
          document.title = "Bauwise"
        })
    };

    const hasTenants = () => {
      return store.getters.availableTenants.length !== 0 && store.getters.currentUser.tenant ? true : false
    }

    const setLang = (lang) => {
      store.dispatch(Actions.UPDATE_LANGUAGE, { language: lang })
        .then(() => {
          localStorage.setItem("lang", lang);
          i18n.locale.value = lang;
          store.dispatch(AggridActions.UPDATE_COMPONENTKEY_ACTION);
        })
        .catch(error => {
          console.log(error)
        })
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLanguageLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    const sortedLanguages = computed(() => {
      return languages.sort((a, b) => {
        return a.order - b.order
      })
    })

    onMounted(() => {
      const tempLang = localStorage.getItem("lang")
      if (tempLang !== currentLang.value) {
        setLang(tempLang)
        return;
      }
      i18n.locale.value = currentLang.value
        ? currentLang.value
        : "en";
    })

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLanguageLocale,
      countries,
      sortedLanguages,
      capitalizeText,
      truncate,
      hasTenants
    };
  },
});
