<template>
  <transition name="fade">
    <div class="el-overlay is-message-box" style="z-index: 2002;" v-if="displayMessageBox">
      <div :aria-label="messageBoxTitle" aria-modal="true" class="el-message-box">
        <div class="el-message-box__header">
          <div class="el-message-box__title">
            <!--v-if-->
            <span>{{ messageBoxTitle }}</span>
          </div>
          <!--v-if-->
        </div>
        <div class="el-message-box__content">
          <div class="el-message-box__container">
            <div
              class="el-message-box__status el-icon-warning"
              :class="`el-icon-${messageBoxType}`"
            ></div>
            <div class="el-message-box__message">
              <slot name="messagebox-msg"></slot>
              <!-- You've been inactive for a while. For your security, we'll automatically sign you out in
              <strong>{{ timeLeft }}</strong>.
              <br />Choose "Stay signed in" to continue or "Sign out" if you're done.-->
            </div>
          </div>
        </div>
        <div class="el-message-box__btns">
          <button
            class="el-button el-button--default el-button--small"
            type="button"
            @click="$emit('emitCancel')"
          >
            <!--v-if-->
            <!--v-if-->
            <span>
              {{ cancelButtonText }}
              <!-- Sign out -->
            </span>
          </button>
          <button
            class="el-button el-button--primary is-plain el-button--small"
            type="button"
            @click="$emit('emitConfirm')"
          >
            <!--v-if-->
            <!--v-if-->
            <span>
              {{ confirmButtonText }}
              <!-- Stay signed in -->
            </span>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseMessageBox2",
  emits: ['emitConfirm', 'emitCancel'],
  props: {
    messageBoxTitle: String,
    confirmButtonText: String,
    cancelButtonText: String,
    messageBoxType: String,
    displayMessageBox: {
      type: Boolean,
      default: false
    },
  },
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>