<template>
  <div class="nav_header_wrapper" :class="classStyle">
    <!--begin::Header-->
    <div id="kt_header" class="header align-items-stretch">
      <!--begin::Container-->
      <div
        :class="{
          'container-fluid': headerWidthFluid,
          container: !headerWidthFluid,
        }"
        class="d-flex align-items-stretch justify-content-between ps-0"
      >
        <!--begin::Aside mobile toggle-->
        <div class="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
          <div class="btn btn-icon btn-active-light-primary" id="kt_aside_mobile_toggle">
            <span class="svg-icon svg-icon-2x mt-1">
              <inline-svg src="media/icons/duotone/Text/Menu.svg" />
            </span>
          </div>
        </div>
        <!--end::Aside mobile toggle-->

        <!--begin::Mobile logo-->
        <div class="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
          <a href="#" class="d-lg-none">
            <h2 class="logo h-15px" style="color: black">Bauwise</h2>
          </a>
        </div>
        <!--end::Mobile logo-->

        <!--begin::Wrapper-->
        <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
          <!--begin::Navbar-->
          <div v-if="!isDocPage" class="d-flex align-items-stretch" id="kt_header_menu_nav">
            <KTMenu></KTMenu>
          </div>
          <!--end::Navbar-->

          <!--begin::Topbar-->
          <div class="d-flex align-items-stretch flex-shrink-0">
            <KTTopbar></KTTopbar>
          </div>

          <!--end::Topbar-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Container-->
    </div>
    <slot v-if="!showToolbar"></slot>
    <!--end::Header-->
    <BaseMessageBox
      :display-message-box="callMessageBox"
      message-box-title="Session Timeout"
      confirm-button-text="Stay signed in"
      cancel-button-text="Sign out"
      message-box-type="warning"
      @emit-confirm="handleConfirmMsgBox"
      @emit-cancel="handleCancelMsgBox"
    >
      <template #messagebox-msg>
        <p>
          You've been inactive for a while. For your security, we'll automatically sign you out in
          <strong>{{ timeLeft }}</strong>.
          <br />Choose "Stay signed in" to continue or "Sign out" if you're done.
        </p>
      </template>
    </BaseMessageBox>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, watchEffect, toRef, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { useIdle, useTimestamp } from "@vueuse/core";
import KTTopbar from "@/layout/header/Topbar.vue";
import KTMenu from "@/layout/header/Menu.vue";
import CookieService from "@/core/services/CookieService";
import { isDocPage } from "@/core/helpers/documentation";
import { Actions } from "@/store/enums/StoreEnums";
import { Token } from "@/core/enums/TokenEnums";
import BaseMessageBox from '@/components/notifications/BaseMessageBox2.vue'
import {
  headerWidthFluid,
} from "@/core/helpers/config";

export default defineComponent({
  name: "Header",
  props: {
    title: String,
    showToolbar: Boolean,
    classStyle: String
  },
  components: {
    KTTopbar,
    KTMenu,
    BaseMessageBox
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const callMessageBox = ref(false);
    // 15 mins in seconds 
    const expiresIn = 900;
    // 30 seconds
    // const expiresIn = 30;
    const futureExpDate = ref(Date.now() + expiresIn * 1000);
    // 10 Minutes Timeout
    const { idle, lastActive } = useIdle(600000);
    // 5 Second Timeout
    // const { idle, lastActive } = useIdle(5 * 1000);
    const now = useTimestamp();

    const setFutureExpDate = () => {
      futureExpDate.value = Date.now() + expiresIn * 1000
    }

    const convertMsToReadableTime = (ms) => {
      const seconds = +(ms / 1000).toFixed(0);
      const minutes = +(ms / (1000 * 60)).toFixed(0);
      const hours = +(ms / (1000 * 60 * 60)).toFixed(0);
      const days = +(ms / (1000 * 60 * 60 * 24)).toFixed(0);
      if (seconds < 60) return seconds + " Sec";
      else if (minutes < 60) return minutes + " Min";
      else if (hours < 24) return hours + " Hrs";
      else return days + " Days";
    };

    /**
     * Returns seconds the user is idle for
     * @returns {number}
     */
    const idledFor = computed(() =>
      Math.floor((now.value - lastActive.value) / 1000)
    );

    /**
     * Returns the time left in string
     * @returns {string}
     */
    const timeLeft = computed(() =>
      convertMsToReadableTime(Math.floor(futureExpDate.value - now.value))
    );

    /**
     * Keeps user signed in and refreshes their access token
     * @returns {void}
     */
    const handleConfirmMsgBox = (): void => {
      const refreshToken = CookieService.getCookie("refresh_token");
      store.dispatch(Actions.REFRESH_TOKEN, {
        [Token.refreshToken]: refreshToken,
      }).finally(() => {
        callMessageBox.value = false
        setFutureExpDate()
      });
    }

    const handleCancelMsgBox = () => {
      store
        .dispatch(Actions.LOGOUT)
        .finally(() => {
          document.title = "Bauwise"
          callMessageBox.value = false
        })
    }

    // Lower timeLeft to 15 mins instead of 45 minutes
    watchEffect(() => {
      // async API call;
      if (idle.value && idledFor.value > 0 && !callMessageBox.value) {
        callMessageBox.value = true
      }
    });

    watchEffect(() => {
      if (lastActive.value) {
        futureExpDate.value = Date.now() + expiresIn * 1000
      }
    })

    watch(idledFor, (val) => {

      if (val === expiresIn || (futureExpDate.value - Date.now()) < 0) {
        handleCancelMsgBox()
      }
    })

    return {
      futureExpDate,
      expiresIn,
      idledFor,
      idle,
      lastActive,
      timeLeft,
      isDocPage,
      headerWidthFluid,
      handleConfirmMsgBox,
      handleCancelMsgBox,
      callMessageBox
    }
  }
})
</script>

<style lang="scss" >
// @import "../../assets/sass/style";
@import "~element-plus/dist/index.css";

@import "../../assets/sass/custom-style.scss";
</style>