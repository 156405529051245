const DocMenuConfig: object = [
  {
    pages: [
      /*{
        heading: "dashboard",
        route: "/dashboard",
        oldView: false,
        svgIcon: "media/icons/duotone/Design/PenAndRuler.svg",
        fontIcon: "bi-app-indicator",
      },*/
      /*{
        heading: "examples",
        route: "/examples",
        oldView: false,
        svgIcon: "media/icons/duotone/Design/PenAndRuler.svg",
        fontIcon: "bi-app-indicator",
      },*/
      {
        heading: "contracts",
        route: "/contracts",
        oldView: false,
        svgIcon: "media/icons/duotone/Design/PenAndRuler.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "rfq",
        route: "/rfq/1/overview",
        oldView: false,
        svgIcon: "media/icons/duotone/Design/PenAndRuler.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  /*
  {
    heading: "finance",
    oldView: true,
    route: "/finance/invoices",
    pages: [
      {
        heading: "Purchase Invoices",
        oldView: true,
        route: "/finance/invoices",
      },
      {
        heading: "Sales Invoices",
        oldView: true,
        route: "/finance/income_invoices",
      },
      {
        heading: "Quotations",
        oldView: true,
        route: "/finance/quotations",
      },
      {
        heading: "Order Confirmations",
        oldView: true,
        route: "/finance/order_confirmation",
      },
    ],
  },
  {
    heading: "contacts",
    oldView: true,
    route: "/contacts/companies",
    pages: [
      {
        heading: "Companies",
        oldView: true,
        route: "/contacts/companies",
      },
      {
        heading: "Company Contacts",
        oldView: true,
        route: "/contacts/companies-contracts",
      },
      {
        heading: "Rate Criteria",
        oldView: true,
        route: "/contacts/rate_criteriums",
      },
      {
        heading: "Categories",
        oldView: true,
        route: "/contacts/categories",
      },
      {
        heading: "Tags",
        oldView: true,
        route: "/contacts/tags",
      },
    ],
  },
  {
    heading: "projects",
    route: "/projects/projects",
    oldView: true,

    pages: [
      {
        heading: "Projects",
        oldView: true,
        route: "/projects/projects",
      },
      {
        heading: "Purchase Orders",
        oldView: true,
        route: "/projects/commitments-all_purchase_orders?show_wp_rows=1",
      },
    ],
  },
  {
    pages: [
      {
        heading: "portfolio",
        route: "/projects/portfolio",
        oldView: true,
        svgIcon: "media/icons/duotone/Design/PenAndRuller.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "reporting",
    oldView: true,
    route: "/projects/portfolio-report_month",
    pages: [
      {
        heading: "Monthly report",
        oldView: true,
        route: "/projects/portfolio-report_month",
      },
      {
        heading: "Gross profit report",
        oldView: true,
        route: "/projects/portfolio-report_gross_profit1",
      },
      {
        heading: "Cash flow",
        oldView: true,
        route: "/wbs/portfell_cashflow",
      },
    ],
  },
  {
    heading: "admin",
    oldView: false,
    route: "/admin",
    pages: [
      {
        sectionTitle: "userManagement",
        route: "/admin",
        svgIcon: "media/icons/duotone/Communication/Add-user.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "manageUsers",
            route: "/admin/manage-users",
          },
          {
            heading: "managePermissions",
            route: "/admin/manage-permissions",
          },
          {
            heading: "manageRoles",
            route: "/admin/manage-roles",
          },
        ],
      },
    ],
  },*/
];

export default DocMenuConfig;
