<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
    <div class="d-flex align-items-stretch flex-shrink-0" v-if="checkUserAuth">
      <!--begin::User-->
      <div class="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
        <!--begin::Menu-->
        <div
          class="cursor-pointer symbol symbol-30px symbol-md-40px"
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <!-- <img src="media/avatars/150-2.jpg" alt="metronic" /> -->
          <div
            class="symbol-label fs-2 fw-bold text-inverse-primary"
            style="background-color: rgb(5, 179, 132)"
          >{{ handleUserInitials }}</div>
        </div>
        <KTUserMenu :current-user="currentUser" :user-initial="handleUserInitials"></KTUserMenu>
        <!--end::Menu-->
      </div>
      <!--end::User -->
    </div>
    <div class="d-flex align-items-stretch flex-shrink-0" v-else>
      <!--begin::Actions-->
      <div class="d-flex align-items-center py-1">
        <!--begin::Wrapper-->
        <div class="me-4">
          <!--begin::Menu-->
          <router-link
            to="/sign-in"
            class="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
          >Login</router-link>

          <!--end::Menu-->
        </div>
        <!--end::Wrapper-->

        <!--begin::Button-->
        <router-link to="/sign-up" class="btn btn-sm btn-primary" id="kt_register_button">Register</router-link>
        <!--end::Button-->
      </div>
    </div>
  </div>
  <!--end::Toolbar wrapper-->
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
  },
  setup() {
    const store = useStore();

    const checkUserAuth = computed(() => {
      return (
        Object.keys(store.state.AuthModule.user).length !== 0 &&
        store.getters.isUserAuthenticated
      );
    });

    const currentUser = computed(() => {
      if (!checkUserAuth.value) return;
      return store.getters.currentUser;
    });

    const handleUserInitials = computed(() => {
      return `${currentUser.value.name.charAt(
        0
      )}${currentUser.value.surname.charAt(0)}`;
    });

    return {
      checkUserAuth,
      currentUser,
      handleUserInitials,
    };
  },
});
</script>
